// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".card{--card-spacing:1.75rem;display:flex;flex-direction:column;border-radius:3px;background-color:#fff;text-decoration:none;color:inherit}.card--default{box-shadow:0 15px 15px -5px #e6e6e6}.card--border{border:1px solid #e3e3e3}.card__header{min-height:200px;background-position:50%;background-size:cover;background-repeat:no-repeat;background-color:#f9f9f9;border-radius:3px 3px 0 0;border-bottom:2px solid #f9f9f9;display:flex;flex-direction:column;justify-content:center;align-items:center}.card__header:first-child:last-child{flex-grow:1}.card__title{font-weight:700;line-height:1;margin-bottom:1rem}.card__content,.card__footer,.card__header{padding:var(--card-spacing)}.card__content{flex-grow:1}.card__footer{background-color:#f9f9f9;border-top:2px solid #f4f4f4}.card__footer-content{display:flex;flex-wrap:wrap;margin-left:-1rem;margin-bottom:-1rem}.card__footer-content>*{margin-left:1rem;margin-bottom:1rem}", ""]);
// Exports
exports.locals = {
	"primary": "#1d56d8",
	"secondary": "#4274e6",
	"warning": "#e9ab05",
	"success": "#0abb59",
	"error": "#e7201b",
	"light-grey": "#f7f7f7",
	"dark": "#272727",
	"grey": "#e3e3e3"
};
module.exports = exports;
