<template>
  <Modal :show.sync="show" clickaway>
    <Card v-if="value" theme="flat" class="kvass-pricing-features">
      <template #default>
        <h2 class="kvass-pricing-features__title">{{ value.label }}</h2>
        <div class="kvass-pricing-features__description" v-html="description"></div>

        <table v-if="value.features">
          <tr>
            <td colspan="2" style="border-top: none;">Dette får du:</td>
          </tr>
          <tr v-for="feature in value.features" :key="feature.label">
            <td>
              <div>{{ feature.label }}</div>
              <div
                v-if="feature.description"
                class="kvass-pricing-features__feature-description"
                v-html="feature.description"
              ></div>
            </td>
            <td>
              <FontAwesomeIcon :icon="['fad', 'check-circle']" />
            </td>
          </tr>
        </table>
      </template>
      <template #footer>
        <ButtonComponent label="Lukk" @click="show = false" />
      </template>
    </Card>
  </Modal>
</template>

<script>
import { ModalComponent as Modal } from 'vue-elder-modal'
import { ButtonComponent } from 'vue-elder-button'
import Card from '@/components/Card'
import mitt from 'mitt'

const EventBus = mitt()

export { EventBus }
export default {
  props: {
    data: Object,
  },
  data() {
    return {
      value: null,
    }
  },
  computed: {
    show: {
      get() {
        return Boolean(this.value)
      },
      set(val) {
        if (!val) this.value = null
      },
    },
    description() {
      if (typeof this.value.description === 'function')
        return this.value.description(this.$parent.$parent.$parent.data, this.$route.query.vendor)
      return this.value.description
    },
  },
  created() {
    EventBus.on('show', id => {
      this.value = [...this.data.Modules, ...this.data.Addons, this.data.Base].find(x => x.value === id)
    })
  },
  components: {
    Modal,
    Card,
    ButtonComponent,
  },
}
</script>

<style lang="scss">
.kvass-pricing-features {
  max-height: 85vh;
  width: 100%;
  max-width: 500px;

  .card__header {
    min-height: initial;
    background-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 0.05);
  }

  .card__content {
    overflow-y: auto;
  }

  &__title {
    margin: 0 0 0.5rem;
  }

  &__feature-description {
    font-size: 0.8em;
    font-weight: normal;
    opacity: 0.7;
  }

  table {
    margin-top: 2rem;
    width: 100%;
    border-collapse: collapse;

    td {
      padding: 10px 0;
      border-top: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      font-weight: bold;

      &:nth-child(2) {
        text-align: center;
        padding: 0 1em 0 1.5em;
      }
    }
  }
}
</style>
