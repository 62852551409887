// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".kvass-pricing-addons__label{display:block;margin-bottom:.75rem}.kvass-pricing-addons__items{display:grid;grid-gap:1rem;grid-template-columns:repeat(auto-fit,minmax(300px,1fr))}", ""]);
// Exports
exports.locals = {
	"primary": "#1d56d8",
	"secondary": "#4274e6",
	"warning": "#e9ab05",
	"success": "#0abb59",
	"error": "#e7201b",
	"light-grey": "#f7f7f7",
	"dark": "#272727",
	"grey": "#e3e3e3"
};
module.exports = exports;
