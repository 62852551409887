<template>
  <div class="kvass-pricing-module">
    <FontAwesomeIcon class="kvass-pricing-module__icon" v-if="content.icon" :icon="content.icon" size="2x" />
    <strong>{{ content.label }}</strong>
    <div class="kvass-pricing-module__cost">
      <div class="kvass-pricing-module__cost-recurring">{{ recurring.value | Currency }} / mnd.</div>
      <div v-if="onetime" class="kvass-pricing-module__cost-recurring">
        {{ onetime.value | Currency }}
      </div>
    </div>
    <FeatureTrigger :value="content" />
    <Tag v-if="content.tag">{{ value.tag }}</Tag>
  </div>
</template>

<script>
import { Currency } from '@/filters'
import FeatureTrigger from '@/components/FeatureTrigger'
import Tag from '@/components/Tag'

export default {
  props: {
    value: Object,
    content: Object,
  },
  computed: {
    onetime() {
      return this.value.price.find(price => price.type === 'fixed' || price.type === 'establishment')
    },
    recurring() {
      return this.value.price.find(price => price.type === 'monthly')
    },
  },
  filters: {
    Currency,
  },
  components: {
    Tag,
    FeatureTrigger,
    Tag,
  },
}
</script>

<style lang="scss">
.kvass-pricing-module {
  --background: #e8eefb;
  --border-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 0.2);

  margin-top: 1rem;
  padding: 1rem;
  border-radius: $border-radius;
  border: 1px solid var(--border-color);
  background-color: hsl(var(--primary-h) 50% 95%);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    top: -0.3rem;
    width: 0.5rem;
    height: 0.5rem;
    border-top: 1px solid var(--border-color);
    border-left: 1px solid var(--border-color);
    background-color: hsl(var(--primary-h) 50% 95%);
  }

  & > svg {
    margin-block: 0.5rem;
  }

  &__cost {
    font-size: 0.8em;
    display: flex;

    & > * + * {
      margin-left: 10px;

      &:before {
        content: ' + ';
      }
    }
  }
}
</style>
