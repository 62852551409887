import { path } from 'ramda'
import Base from './base'
import Models from './models'

function getVendor(vendor = 'default') {
  let Vendor = Models[vendor]
  if (!Vendor) Vendor = Models['default']
  let merge = key => {
    let makeArray = v => (v instanceof Array ? v : [v])
    let isArray = Vendor[key] instanceof Array
    let target = isArray ? Vendor[key] : [Vendor[key]]
    let result = target
      .map(x => {
        let match = makeArray(Base[key]).find(b => b.value === x.value)
        if (!match) return
        return { ...match, ...x }
      })
      .filter(Boolean)

    return isArray ? result : result[0]
  }

  return {
    ...Vendor,
    Value: vendor,
    Base: merge('Base'),
    Addons: merge('Addons'),
    Modules: merge('Modules'),
  }
}

function RoundToNearest(value, rounding, func = 'ceil') {
  return Math[func](value / rounding) * rounding
}

function Calculate(data, items, vendor) {
  const { base, module, units, addons } = data
  let result = {
    fixed: 0,
    recurring: 0,
  }

  const Module = items.find(i => i.type === 'module')
  const Base = items.find(i => i.type === 'app')
  const Addons = Module.addons.filter(a => addons.includes(a.id))

  function addToResult(price) {
    if (price.type === 'monthly') {
      result['recurring'] += price.value
    }
    if (price.type === 'fixed' || price.type === 'establishment') {
      result['fixed'] += price.value
    }
  }

  Module.price.forEach(addToResult)

  Addons.forEach(addon => {
    const include = vendor.Addons.find(x => x.value === addon.id)?.include
    if (include) {
      const items = Module.addons.filter(y => include.includes(y.id))
      items.forEach(item => {
        item.price.forEach(addToResult)
      })
    }

    addon.price.forEach(addToResult)
  })

  if (base) {
    Base.price.forEach(addToResult)
  }

  return result
}

function isInRange(value, condition) {
  const greater = value >= condition.gte
  const less = value <= condition.lte

  if (!condition.lte) {
    return greater
  }

  return greater && less
}

export { Calculate, getVendor, isInRange }
