<template>
  <button @click.stop="showFeatures" class="kvass-pricing-feature-trigger">
    <span>Se hva som er inkludert</span>
    <FontAwesomeIcon :icon="['fad', 'sparkles']" />
  </button>
</template>

<script>
import { EventBus } from '@/components/Features'
export default {
  props: {
    value: Object,
  },
  methods: {
    showFeatures() {
      EventBus.emit('show', this.value.value)
    },
  },
}
</script>

<style lang="scss">
.kvass-pricing-feature-trigger {
  color: var(--primary);
  font-weight: bold;
  font-size: 0.8em;
  cursor: pointer;
  margin-top: 10px;
  border: 1px solid rgba(black, 0.075);
  border-radius: $border-radius;
  padding: 2px 8px;
  transition: background-color 150ms ease-out;

  &:hover {
    background-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 0.1);
    border-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 0.3);
  }

  svg {
    margin-left: 5px;
  }
}
</style>
