import { library } from '@fortawesome/fontawesome-svg-core'

/* Solid icons */
// import { faCircle as fasCircle } from '@fortawesome/pro-solid-svg-icons/faCircle'

// library.add(fasCircle)

/* Regular icons */
import { faLongArrowRight as farLongArrowRight } from '@fortawesome/pro-regular-svg-icons/faLongArrowRight'
import { faLongArrowUp as farLongArrowUp } from '@fortawesome/pro-regular-svg-icons/faLongArrowUp'
import { faTable as farTable } from '@fortawesome/pro-regular-svg-icons/faTable'
import { faCalculator as farCalculator } from '@fortawesome/pro-regular-svg-icons/faCalculator'

library.add(farLongArrowRight, farLongArrowUp, farTable, farCalculator)

/* Light icons */
// import { faLongArrowRight as falLongArrowRight } from '@fortawesome/pro-light-svg-icons/faLongArrowRight'

// library.add(falLongArrowRight)

/* Brand icons */
// import { faFacebook as fabFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'

// library.add(fabFacebook)

/* Duotone icons */
import { faFileContract as fadFileContract } from '@fortawesome/pro-duotone-svg-icons/faFileContract'
import { faSign as fadSign } from '@fortawesome/pro-duotone-svg-icons/faSign'
import { faFillDrip as fadFillDrip } from '@fortawesome/pro-duotone-svg-icons/faFillDrip'
import { faBrowser as fadBrowser } from '@fortawesome/pro-duotone-svg-icons/faBrowser'
import { faCheckCircle as fadCheckCircle } from '@fortawesome/pro-duotone-svg-icons/faCheckCircle'
import { faDraftingCompass as fadDraftingCompass } from '@fortawesome/pro-duotone-svg-icons/faDraftingCompass'
import { faHome as fadHome } from '@fortawesome/pro-duotone-svg-icons/faHome'
import { faAngleUp as fadAngleUp } from '@fortawesome/pro-duotone-svg-icons/faAngleUp'
import { faAngleDown as fadAngleDown } from '@fortawesome/pro-duotone-svg-icons/faAngleDown'
import { faQuestionCircle as fadQuestionCircle } from '@fortawesome/pro-duotone-svg-icons/faQuestionCircle'
import { faSparkles as fadSparkles } from '@fortawesome/pro-duotone-svg-icons/faSparkles'
import { faDatabase as fadDatabase } from '@fortawesome/pro-duotone-svg-icons/faDatabase'
import { faCommentAltExclamation as fadCommentAltExclamation } from '@fortawesome/pro-duotone-svg-icons/faCommentAltExclamation'

library.add(
  fadDatabase,
  fadSparkles,
  fadQuestionCircle,
  fadFileContract,
  fadSign,
  fadFillDrip,
  fadBrowser,
  fadCheckCircle,
  fadDraftingCompass,
  fadHome,
  fadAngleUp,
  fadAngleDown,
  fadCommentAltExclamation
)
