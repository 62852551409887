import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'
import VTooltip from 'v-tooltip'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [],
})

import './styles/main.scss'
import './fa'

Vue.config.productionTip = false

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
Vue.component('FontAwesomeIcon', FontAwesomeIcon)

Vue.use(VTooltip)

new Vue({
  render: h => h(App),
  router,
}).$mount('#kvass-pricing')
