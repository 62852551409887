// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".kvass-pricing-module{--background:#e8eefb;--border-color:hsla(var(--primary-h),var(--primary-s),var(--primary-l),0.2);margin-top:1rem;padding:1rem;border-radius:3px;border:1px solid var(--border-color);background-color:hsl(var(--primary-h),50%,95%);display:flex;flex-direction:column;align-items:center;position:relative}.kvass-pricing-module:after{content:\"\";position:absolute;left:50%;transform:translateX(-50%) rotate(45deg);top:-.3rem;width:.5rem;height:.5rem;border-top:1px solid var(--border-color);border-left:1px solid var(--border-color);background-color:hsl(var(--primary-h),50%,95%)}.kvass-pricing-module>svg{margin-block:.5rem}.kvass-pricing-module__cost{font-size:.8em;display:flex}.kvass-pricing-module__cost>*+*{margin-left:10px}.kvass-pricing-module__cost>*+:before{content:\" + \"}", ""]);
// Exports
exports.locals = {
	"primary": "#1d56d8",
	"secondary": "#4274e6",
	"warning": "#e9ab05",
	"success": "#0abb59",
	"error": "#e7201b",
	"light-grey": "#f7f7f7",
	"dark": "#272727",
	"grey": "#e3e3e3"
};
module.exports = exports;
