<template>
  <div>
    <InputComponent class="kvass-pricing-units" label="Hvor mange enheter skal du selge totalt?">
      <template #default>
        <ChoiceComponent v-model="valueComp" :values="values" />
      </template>
    </InputComponent>
    <Module :value="item" :content="itemContent" />
  </div>
</template>

<script>
import { InputComponent } from 'vue-elder-input'
import { ChoiceComponent } from 'vue-elder-choice'
import { ValueComp } from '@/utils'
import { UnitSteps } from '@/pricing'
import { Currency } from '@/filters'
import Module from './Module'

export default {
  props: {
    value: Number,
    values: Array,
    item: Object,
    content: Array,
  },
  filters: {
    Currency,
  },
  computed: {
    valueComp: ValueComp(),
    itemContent() {
      if (this.value <= 1) {
        return this.content.find(item => item.value === 'residential')
      }
      return this.content.find(item => item.value === this.item.id)
    },
  },
  components: {
    InputComponent,
    ChoiceComponent,
    Module,
  },
}
</script>

<style lang="scss">
.kvass-pricing-units {
  .elder-input__field {
    border: none;
  }
}

.price {
  margin-top: 0.5em;
  font-size: 0.7em;
  font-weight: bold;
  white-space: nowrap;
}
</style>
