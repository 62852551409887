// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".pricing-calculator__view-toggle{border:1px solid #e3e3e3;padding:.25rem .5rem;border-radius:3px;font-size:.8em;display:flex;align-items:center}.pricing-calculator__view-toggle:hover{border-color:#cacaca}.pricing-calculator__view-toggle svg{margin-left:.5rem}@media print{.pricing-calculator__view-toggle{display:none}}", ""]);
// Exports
exports.locals = {
	"primary": "#1d56d8",
	"secondary": "#4274e6",
	"warning": "#e9ab05",
	"success": "#0abb59",
	"error": "#e7201b",
	"light-grey": "#f7f7f7",
	"dark": "#272727",
	"grey": "#e3e3e3"
};
module.exports = exports;
