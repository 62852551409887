import Template from '../base'
import Parent from './mestergruppen-innkjop-trinn-1'

import { Currency } from '@/filters'

const Addons = [
  {
    value: 'presentation',
    cost: {
      project: { onetime: 50000, recurring: 0 },
      residential: { onetime: 0, recurring: 150 },
    },
  },
  {
    value: 'bid',
    cost: {
      project: { onetime: 0, recurring: 1200 },
    },
  },
  {
    value: 'purchase',
    cost: {
      project: { onetime: 0, recurring: 900 },
      residential: { onetime: 250, recurring: 0 },
    },
  },
  {
    value: 'shop',
    cost: {
      project: { onetime: 0, recurring: 1650 },
    },
  },
  {
    value: 'shop-purchase',
    cost: {
      project: { onetime: 0, recurring: 2100 },
      residential: { onetime: 650, recurring: 0 },
    },
  },
]

const Base = {
  value: 'base',
  label: 'Grunnpakke',
  description:
    'Presenter og selg eiendomsprosjekter på en helt ny måte. Du oppretter raskt og enkelt selv boliger og prosjekter med ønskede moduler.',
  campaign:
    '<b>Kampanje<i class="far fa-long-arrow-right" style="margin: 0 .25rem;position:relative;top:1px;"></i> Registrer deg før 31.12.21 og slipp etableringskostnaden.</b>',
  cost: {
    onetime: 9900,
    recurring: 800,
  },
}

const Modules = [
  {
    value: 'project',
    cost: {
      onetime: 0,
      recurring: 500,
    },
  },
  {
    value: 'residential',
    cost: {
      onetime: 0,
      recurring: 100,
    },
  },
]

const Discounts = [
  {
    value: 400,
    type: 'fixed',
    label: 'Prosjekt 1-9 - Bud',
    usableWith: ['bid'],
    priceTypes: ['recurring'],
    criteria: data => {
      return data.units < 10 && data.module === 'project'
    },
  },
  {
    value: 250,
    type: 'fixed',
    label: 'Prosjekt 1-9 - Kjøpekontrakt',
    usableWith: ['purchase'],
    priceTypes: ['recurring'],
    criteria: data => {
      return data.units < 10 && data.module === 'project'
    },
  },
  {
    value: 650,
    type: 'fixed',
    label: 'Prosjekt 1-9 - Tilvalg',
    usableWith: ['shop-presentation'],
    priceTypes: ['recurring'],
    criteria: data => {
      return data.units < 10 && data.module === 'project'
    },
  },
  {
    value: 700,
    type: 'fixed',
    label: 'Prosjekt 1-9 - Tilvalg',
    usableWith: ['shop-sale'],
    priceTypes: ['recurring'],
    criteria: data => {
      return data.units < 10 && data.module === 'project'
    },
  },
  {
    value: 40000,
    type: 'fixed',
    label: 'Prosjekt 2-9 (Kostnadsfri)',
    usableWith: ['presentation'],
    priceTypes: ['onetime'],
    criteria: data => {
      return data.units <= 9 && data.units >= 2 && data.module === 'project'
    },
  },
  {
    value: 25000,
    type: 'fixed',
    label: 'Prosjekt 10-39',
    usableWith: ['presentation'],
    priceTypes: ['onetime'],
    criteria: data => {
      return data.units >= 10 && data.units <= 39 && data.module === 'project'
    },
  },
]

const UnitSteps = [
  { value: 2, label: '2 - 9' },
  { value: 10, label: '10 - 39' },
  { value: 40, label: '40+' },
]

export default {
  Logo: 'https://www.kvass.no/wp-content/uploads/sites/35/2021/01/boligporten-logo-04.png',
  LogoBackground: '#05798b',
  Theme: '#05798b',
  Disclaimer: Parent.Disclaimer,
  SubscriptionInfo: Parent.SubscriptionInfo,
  Addons,
  Modules,
  Discounts,
  Base,
  UnitSteps,
  Discounts,
  RelatedVendors: [],
}
