<template>
  <table class="pdf">
    <thead>
      <tr>
        <th :colspan="value.steps.length + 1">
          <div class="pdf__header">
            <h1>{{ value.name }}</h1>
            <img :src="logo" :alt="value.name" />
            <ViewToggle />
          </div>
        </th>
      </tr>
      <tr class="type--app">
        <td>
          {{ getData('app').label }}
        </td>
        <td v-for="step in value.steps" :key="'app' + step.label">
          <div v-for="(price, index) in getValue('app', null, step.value)" :key="index">
            {{ price.value | Currency }} <span :class="[price.type]">{{ getSuffix(price) }}</span>
          </div>
        </td>
      </tr>
      <tr class="pdf__units">
        <th>Antall enheter:</th>
        <th v-for="step in value.steps" :key="step.label">{{ step.label }}</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="item in value.items.filter(i => i.type === 'module' && getData(i.type, i.id))">
        <tr :key="item.id">
          <td>
            {{ getData(item.type, item.id).label }}
          </td>
          <td v-for="step in value.steps" :key="item.id + step.label">
            <div v-for="(price, index) in getValue(item.id, null, step.value)" :key="index">
              {{ price.value | Currency }} <span :class="[price.type]">{{ getSuffix(price) }}</span>
            </div>
          </td>
        </tr>
        <tr :key="item.id + ':' + addon.id" v-for="addon in getFilteredAddons(item.addons)">
          <td>
            <span class="addon">
              <span>
                <span>{{ getData('addon', addon.id).label }}</span>
                <span class="addon__description">
                  {{ getData('addon', addon.id).description }}
                </span>
              </span>
            </span>
          </td>
          <td v-for="step in value.steps" :key="addon.id + step.label">
            <span
              v-for="(price, index) in getValue(item.id, addon.id, step.value)"
              :key="index"
              style="white-space: nowrap; display: inline-block"
              >{{ price.value | Currency }} {{ getSuffix(price) }}</span
            >
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import { ParseCondition } from '@/utils'
import { Currency } from '@/filters'
import Base from '@/pricing/base'
import ViewToggle from './ViewToggle.vue'

export default {
  props: {
    value: Object,
  },
  components: {
    ViewToggle,
  },
  computed: {
    logo() {
      return `${process.env.VUE_APP_MANAGER_ENDPOINT}/api/vendor/${this.$route.query.vendor || 'kvass'}/logo`
    },
  },
  methods: {
    getValue(item, addon, step) {
      let s = this.value.steps.find(s => s.value === step)
      if (!s) return []

      let i = s.items.find(it => it.id === item)
      if (!i) return []

      if (!addon) return i.price

      let a = i.addons.find(ad => ad.id === addon)
      if (!a) return []

      const match = this.getData('addon', a.id)
      if (!match) return []

      if (match.condition) {
        let parsed = ParseCondition(match.condition).units
        if (!(parsed.gte <= step && (!parsed.lte || parsed.lte >= step))) return []
      }

      if (!match.include) return a.price

      // if addons includes other addons, sum their prices
      let price = []
      match.include.forEach(value => {
        const includedPrices = this.getValue(item, value, step)
        includedPrices.forEach((p, i) => {
          price.push({
            type: p.type,
            value: p.value + a.price[i].value,
          })
        })
      })

      return price
    },
    getData(type, id) {
      switch (type) {
        case 'app':
          return Base.Base
        case 'module':
          return Base.Modules.find(m => m.value === id)
        case 'addon':
          return Base.Addons.find(a => a.value === id)
      }
    },
    getSuffix(price) {
      switch (price.type) {
        case 'monthly':
          return ' / mnd.'
        case 'fixed':
          return ''
        case 'establishment':
          return '(Etab.)'
      }
    },
    getFilteredAddons(addons) {
      return addons.filter(addon => this.getData('addon', addon.id))
    },
  },
  filters: {
    Currency,
  },
}
</script>

<style lang="scss">
.pdf {
  border-radius: $border-radius;
  background-color: white;
  border-collapse: collapse;
  width: 100%;
  max-width: 1000px;
  border: 2px solid rgba(black, 0.03);
  margin: 0 auto;

  @media print {
    font-size: 0.8em;
  }

  h1 {
    margin: 0;
    font-size: 1.5em;
  }

  th {
    background-color: rgba(black, 0.025);

    &:first-child {
      text-align: left;
    }

    &:first-child:last-child {
      // text-align: center;
    }
  }

  th,
  td {
    border: 1px solid #eaeaea;
    padding: 1rem;
  }

  td {
    &:nth-child(n + 2) {
      text-align: right;
    }

    &:first-child {
      background-color: rgba(black, 0.025);
    }

    &:empty {
      opacity: 0.7;
      font-size: 0.7em;
      text-align: center;
      background-color: rgba(black, 0.05);
      &:before {
        content: 'Ikke tilgjengelig';
      }
    }
  }

  div {
    white-space: nowrap;

    &:nth-child(n + 2) {
      &:before {
        content: '+ ';
      }
    }
  }

  .establishment,
  .monthly,
  .fixed {
    display: inline;
    font-size: 0.7em;
    opacity: 0.7;
  }

  &__units {
    background-color: rgba(black, 0.03);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  img {
    max-height: 25px;
  }

  .addon {
    display: flex;
    align-items: center;

    &:before {
      content: '└';
      margin-right: 1rem;
      opacity: 0.2;
    }

    &__description {
      display: block;
      font-size: 0.7em;
      opacity: 0.7;
      margin-top: 2px;
    }
  }
}
</style>
