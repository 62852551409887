import Template from '../base'

export default {
  Name: 'Kvass',
  LogoBackground: '#f5f5fb',
  Logo: 'https://www.kvass.no/wp-content/uploads/sites/35/2020/01/Horizontal.png',
  Disclaimer: 'Produkter faktureres pr. kvartal og alle priser er ekskl. mva.',
  SubscriptionInfo: `<b>Avtalens varighet</b><div>Alle produkter som aktiveres har en bindingstid på $lockInPeriod.
  Produkter fornyes automatisk løpende med $noticePeriod om den ikke sies opp av en av
  partene skriftlig før inneværende periode utløper.</div>`,
  Rounding: 50,
  ...Template,
}
