// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".kvass-pricing-addon{display:flex;align-items:center;width:100%;border:1px solid #e3e3e3;border-radius:3px;padding:1.25rem 1.25rem 1.25rem 1.25rem;position:relative;text-align:left;cursor:pointer}.kvass-pricing-addon--active{color:var(--primary);background-color:hsla(var(--primary-h),var(--primary-s),var(--primary-l),.1);border-color:hsla(var(--primary-h),var(--primary-s),var(--primary-l),.2)}.kvass-pricing-addon__header{display:flex;justify-content:space-between;align-items:baseline;margin-bottom:5px}.kvass-pricing-addon__cost{font-size:.7em;display:flex;white-space:nowrap}.kvass-pricing-addon__cost>*+*{margin-left:10px}.kvass-pricing-addon__cost>*+:before{content:\" + \"}.kvass-pricing-addon__content{flex-grow:1;margin-right:.625rem;align-self:flex-start}.kvass-pricing-addon__label{font-weight:700}.kvass-pricing-addon__description{font-size:.8em;opacity:.5}.kvass-pricing-addon__icon{margin-right:1.25rem;width:40px}", ""]);
// Exports
exports.locals = {
	"primary": "#1d56d8",
	"secondary": "#4274e6",
	"warning": "#e9ab05",
	"success": "#0abb59",
	"error": "#e7201b",
	"light-grey": "#f7f7f7",
	"dark": "#272727",
	"grey": "#e3e3e3"
};
module.exports = exports;
