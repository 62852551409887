import Template from '../base'

export default {
	...Template,
	name: 'ForhandlerInnkjop',
	Description:
		'<b>Innkjøpspriser</b><br>Priser er justert i forhold til antall kunder som er i gang - velg trinn nedenfor:',
	RelatedVendors: [
		// { value: 'forhandler', label: 'Utsalgspriser (Forslag)' },
		{ value: 'forhandler-trinn-1', label: '1-25 kunder (ca. 20%)' },
		{ value: 'forhandler-trinn-2', label: '26-99 kunder (ca. 30%)' },
		{ value: 'forhandler-trinn-3', label: '100+ kunder (ca. 35%)' },
	],
}