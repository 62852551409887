<template>
  <InputComponent label="Er du ny kunde?" class="kvass-pricing-base">
    <template #default>
      <div
        class="kvass-pricing-base__choice"
        :class="{ 'kvass-pricing-base__choice--active': value }"
        @click="$emit('input', !value)"
      >
        <div class="kvass-pricing-base__content">
          <FontAwesomeIcon :icon="['fad', 'database']" size="2x" class="kvass-pricing-base__icon" />
          <div>
            <label class="kvass-pricing-base__label">{{ content.label }}</label>
            <div class="kvass-pricing-base__cost">
              <span class="kvass-pricing-base__cost-recurring">{{ recurring.value | Currency }} / mnd.</span>
              <span class="kvass-pricing-base__cost-onetime"> + {{ onetime.value | Currency }} (Etablering)</span>
              <div v-if="content.campaign" class="kvass-pricing-base__campaign" v-html="content.campaign"></div>
            </div>
            <FeatureTrigger :value="content" />
          </div>
        </div>
      </div>
    </template>
  </InputComponent>
</template>

<script>
import { Currency } from '@/filters'

import FeatureTrigger from '@/components/FeatureTrigger'
import { InputComponent } from 'vue-elder-input'

export default {
  props: {
    value: Boolean,
    pricing: Object,
    content: Object,
    step: Object,
  },
  computed: {
    icon() {
      return `${process.env.VUE_APP_MANAGER_ENDPOINT}/api/vendor/${this.pricing.name.toLowerCase()}/favicon`
    },
    item() {
      return this.step.items.find(step => step.type === 'app')
    },
    recurring() {
      return this.item.price.find(price => price.type === 'monthly')
    },
    onetime() {
      return this.item.price.find(price => price.type === 'establishment')
    },
  },
  components: {
    FeatureTrigger,
    InputComponent,
  },
  filters: {
    Currency,
  },
}
</script>

<style lang="scss">
.kvass-pricing-base {
  .elder-input__field {
    border: none;
  }

  &__label {
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-right: 1.5rem;
  }

  &__campaign {
    background-color: rgba(0, 0, 0, 0.05);
    margin-top: 0.5rem;
    border-radius: $border-radius;
    padding: 3px 8px;
  }

  &__choice {
    cursor: pointer;
    transition: background-color 150ms ease-out, color 150ms ease-out;
    border: 1px solid var(--vue-elder-border-color, #eaeaea);
    border-radius: var(--vue-elder-border-radius, $border-radius);
    padding: 1rem 2rem;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    align-items: center;

    &--active {
      color: var(--primary);
      background-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 0.1);
      border-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 0.2);
    }
  }

  &__description {
    font-size: 0.8em;
    opacity: 0.8;
  }

  &__cost {
    font-size: 0.8em;
    margin-top: 0.5em;
    font-weight: bold;

    &-recurring,
    &-onetime {
    }
  }
}
</style>
