// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".kvass-pricing-tag{font-size:.7em;padding:2px 5px;background-color:var(--primary);color:#fff;border-radius:0 0 0 3px;position:absolute;top:0;right:0}", ""]);
// Exports
exports.locals = {
	"primary": "#1d56d8",
	"secondary": "#4274e6",
	"warning": "#e9ab05",
	"success": "#0abb59",
	"error": "#e7201b",
	"light-grey": "#f7f7f7",
	"dark": "#272727",
	"grey": "#e3e3e3"
};
module.exports = exports;
