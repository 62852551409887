import Template from './mestergruppen-innkjop-trinn-1'

const Discounts = [
  {
    value: 0.1,
    order: 2,
    label: 'Mestergruppen avslag',
    usableWith: ['presentation'],
    criteria: () => true,
  },
  {
    value: 0.15,
    order: 2,
    label: 'Mestergruppen avslag',
    usableWith: ['!presentation', '!base'],
    criteria: () => true,
  },
  ...Template.Discounts,
]

export default {
  ...Template,
  Discounts,
}
