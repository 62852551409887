function ValueComp() {
  return {
    get() {
      return this.value
    },
    set(val) {
      this.$emit('input', val)
    },
  }
}

function HexToHSL(H) {
  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0
  if (H.length == 4) {
    r = '0x' + H[1] + H[1]
    g = '0x' + H[2] + H[2]
    b = '0x' + H[3] + H[3]
  } else if (H.length == 7) {
    r = '0x' + H[1] + H[2]
    g = '0x' + H[3] + H[4]
    b = '0x' + H[5] + H[6]
  }
  // Then to HSL
  r /= 255
  g /= 255
  b /= 255
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0

  if (delta == 0) h = 0
  else if (cmax == r) h = ((g - b) / delta) % 6
  else if (cmax == g) h = (b - r) / delta + 2
  else h = (r - g) / delta + 4

  h = Math.round(h * 60)

  if (h < 0) h += 360

  l = (cmax + cmin) / 2
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))
  s = +(s * 100).toFixed(1)
  l = +(l * 100).toFixed(1)

  return { h, s, l }
}

function Clone() {
  return JSON.parse(JSON.stringify.apply(this, arguments))
}

function ParseMonths(string) {
  return string.replace('M', ' måneder')
}

// Can currently only handle "units"

function ParseCondition(value) {
  const NormalizeConditionValue = value => {
    if (/\d+-\d+/.test(value)) {
      let [gte, lte] = value.split('-')
      return { lte: parseInt(lte), gte: parseInt(gte) }
    }

    if (value.endsWith('+')) return { gte: parseInt(value) }

    return { lte: parseInt(value), gte: parseInt(value) }
  }

  if (!value) return {}
  let conditions = value.split('|')
  return conditions.reduce((res, cur) => {
    let [key, value] = cur.split(':')

    switch (key) {
      case 'units':
        res[key] = NormalizeConditionValue(value)
    }

    return res
  }, {})
}

export { ValueComp, HexToHSL, Clone, ParseMonths, ParseCondition }
