// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".kvass-pricing-base .elder-input__field{border:none}.kvass-pricing-base__content{display:flex;align-items:center}.kvass-pricing-base__icon{margin-right:1.5rem}.kvass-pricing-base__campaign{background-color:rgba(0,0,0,.05);margin-top:.5rem;border-radius:3px;padding:3px 8px}.kvass-pricing-base__choice{cursor:pointer;transition:background-color .15s ease-out,color .15s ease-out;border:1px solid var(--vue-elder-border-color,#eaeaea);border-radius:var(--vue-elder-border-radius,3px);padding:1rem 2rem;display:grid;grid-template-columns:repeat(auto-fit,minmax(300px,1fr));align-items:center}.kvass-pricing-base__choice--active{color:var(--primary);background-color:hsla(var(--primary-h),var(--primary-s),var(--primary-l),.1);border-color:hsla(var(--primary-h),var(--primary-s),var(--primary-l),.2)}.kvass-pricing-base__description{font-size:.8em;opacity:.8}.kvass-pricing-base__cost{font-size:.8em;margin-top:.5em;font-weight:700}", ""]);
// Exports
exports.locals = {
	"primary": "#1d56d8",
	"secondary": "#4274e6",
	"warning": "#e9ab05",
	"success": "#0abb59",
	"error": "#e7201b",
	"light-grey": "#f7f7f7",
	"dark": "#272727",
	"grey": "#e3e3e3"
};
module.exports = exports;
