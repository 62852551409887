<template>
  <div class="kvass-pricing-addons">
    <label class="kvass-pricing-addons__label">Velg produkter:</label>
    <div class="kvass-pricing-addons__items">
      <Addon
        v-for="item in availableAddons"
        :key="item.id"
        :value="item"
        :include="getIncluded(item)"
        :content="content"
        :active="value.includes(item.id)"
        @click="set(item)"
      />
    </div>
  </div>
</template>

<script>
import { ValueComp, ParseCondition } from '@/utils'
import Addon from './Addon'

export default {
  props: {
    value: Array,
    addons: Array,
    content: Array,
    units: Number,
  },
  computed: {
    valueComp: ValueComp(),
    availableAddons() {
      return this.addons.filter(addon =>
        this.content.find(item => {
          if (item.value !== addon.id) return
          if (item.condition) {
            let parsed = ParseCondition(item.condition).units
            return parsed.gte <= this.units && (!parsed.lte || parsed.lte >= this.units)
          }
          return true
        }),
      )
    },
  },
  methods: {
    add(item) {
      if (this.value.includes(item.id)) return

      this.value.push(item.id)

      let contentItem = this.content.find(i => i.value == item.id)

      let uncompatibleAddons = contentItem.uncompatibleAddons || []
      uncompatibleAddons.forEach(id => {
        let index = this.value.indexOf(id)
        if (index > -1) this.value.splice(index, 1)
      })

      // let include = contentItem.include || []
      // include
      //   .map(id => this.addons.find(a => a.id === id))
      //   .filter(Boolean)
      //   .forEach(item => this.add(item))

      this.$emit('input', this.value)
    },
    remove(item) {
      this.$emit(
        'input',
        this.value.filter(v => v !== item.id),
      )
    },
    set(item) {
      if (this.value.includes(item.id)) return this.remove(item)
      return this.add(item)
    },
    getIncluded(item) {
      const c = this.content.find(x => x.value === item.id)
      if (!c) return []

      const a = this.addons.filter(x => c.include?.includes(x.id))
      return a
    },
  },
  components: {
    Addon,
  },
}
</script>

<style lang="scss">
.kvass-pricing-addons {
  &__label {
    display: block;
    margin-bottom: 0.75rem;
  }

  &__items {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}
</style>
