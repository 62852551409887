const Addons = [
  {
    value: 'presentation',
    label: 'Prosjekt-/boligside',
    description: 'Sett raskt opp en nettside med boligvelger.',
    icon: ['fad', 'browser'],
    features: [
      {
        label: '"Kommer til salg" nettside',
        description: 'Sett opp en «kommer til salg» nettside på under 2 minutter',
      },
      {
        label: 'Prosjektside',
        description: 'Lag en nettside med få trykk og oppdater den enkelt selv',
      },
      {
        label: 'Boligsider',
        description:
          'Hver bolig får sin egen boligside som gir deg mulighet til å presentere og markedsføre boligen på en helt ny måte',
      },
      {
        label: 'Temamaler og fargeprofil',
        description: 'Velg blant forskjellige maler eller bestill et unikt design.',
      },
      {
        label: 'Boligvelger',
        description: 'Last opp et oversiktsbilde og sett enkelt opp en velger for tomter, boliger eller leiligheter',
      },
      { label: 'Analyse & besøksdata', description: 'Ferdig integrert med Google Analytics / Google Tag Manager' },
      { label: 'Min Side', description: 'Gi dine kunder tilgang til Min Side via egen login' },
    ],
  },
  {
    value: 'bid',
    label: 'Salgsprosess - Kjøpekontrakt & Kjøpsmodul',
    description: 'Selg enkelt & digitalt via prosjekt-/boligsiden.',
    icon: ['fad', 'sign'],
    uncompatibleAddons: ['purchase'],
    features: [
      { label: 'Digital kjøpsprosess', description: 'Last opp din kjøpsbekreftelse og selg digitalt' },
      { label: 'Dokumentarkiv', description: 'Ha tilgang på alle kontrakter i arkivet' },
      {
        label: 'Signering med BankID',
        description: 'La alle parter signere med BankID',
      },
      { label: 'Min Side', description: 'Gi dine kunder tilgang til Min Side via egen login' },
    ],
  },
  {
    value: 'purchase',
    label: 'Salgsprosess - Kjøpekontrakt',
    description: 'Send ut og signer kjøpekontrakten digitalt.',
    icon: ['fad', 'file-contract'],
    uncompatibleAddons: ['bid'],
    condition: 'units:1',
    features: [
      { label: 'Digital kjøpsprosess', description: 'Last opp din kjøpekontrakt og la kjøperen signere digitalt' },
      { label: 'Dokumentarkiv', description: 'Ha tilgang på alle kontrakter i arkivet' },
      {
        label: 'Signering med BankID',
        description: 'La alle parter signere med BankID',
      },
      { label: 'Min Side', description: 'Gi dine kunder tilgang til Min Side via egen login' },
    ],
  },
  {
    value: 'shop',
    label: 'Tilvalg - Presentasjon',
    description: 'Presenter tilvalg digitalt via Prosjekt-/boligsiden eller med en egen lenke.',
    icon: ['fad', 'fill-drip'],
    uncompatibleAddons: ['shop-purchase'],
    features: [
      { label: 'Presentasjon av tilvalg', description: 'Presenter tilvalg helt ned på bolignivå' },
      { label: 'Min Side', description: 'Gi dine kunder tilgang til Min Side via egen login' },
    ],
  },
  {
    value: 'shop-purchase',
    label: 'Tilvalg - Presentasjon & Salg',
    description: 'Selg tilvalg digitalt via Prosjekt-/boligsiden, Min Side eller med en egen lenke.',
    icon: ['fad', 'file-contract'],
    include: ['shop'],
    uncompatibleAddons: ['shop'],
    features: [
      { label: 'Presentasjon av tilvalg', description: 'Presenter tilvalg helt ned på bolignivå' },
      {
        label: 'Digital tilvalgsprosess',
        description: 'Klargjør tilvalgsbestillinger og sett frist',
      },
      { label: 'Dokumentarkiv', description: 'Ha tilgang på alle kontrakter i arkivet' },
      {
        label: 'Signering med BankID',
        description: 'La alle parter signere med BankID',
      },
      { label: 'Min Side', description: 'Gi dine kunder tilgang til Min Side via egen login' },
    ],
  },
  {
    value: 'complaint',
    label: 'Reklamasjonsmodul',
    description: 'La dine kunder sende inn reklamasjoner og henvendelser på en brukervennlig måte.',
    icon: ['fad', 'comment-alt-exclamation'],
    features: [
      {
        label: 'Løsning for dialog',
        description: 'Kommuniser med kjøper via Min Side'
      },
      {
        label: 'Reklamasjonshåndtering',
        description: 'Kjøper kan registrere reklamasjoner via Min Side'
      },
      {
        label: 'Oversikt over reklamasjoner, ansvarspersoner og frister samt live status',
        description: 'På Min Side får kjøper full oversikt og live status på reklamasjonssaker'
      },
    ],
  },
  {
    value: 'residential-account',
    label: 'Min Side',
    description: 'Gi dine kunder tilgang til Min Side. (inkl. ved aktiv prosjektlisens)',
    icon: ['fad', 'home'],
    features: [
      {
        label: 'Presentasjon av enheten',
        description: 'Lekker presentasjon av kjøpt enhet'
      },
      {
        label: 'Deling av kontrakter',
        description: 'Last opp kontrakter på Min Side'
      },
      {
        label: 'Løsning for dialog',
        description: 'Kommuniser med kunde via Min Side'
      },
      {
        label: 'FDV-håndtering',
        description: 'Last opp FDV på Min Side'
      },
      {
        label: 'Salg av tilvalg (forutsetter aktivering av tilvalgsmodul)',
        description: 'Selg tilvalg direkte via Min Side, med BankID signering'
      },
      {
        label: 'Deling av tilvalgskontrakter (forutsetter aktivering av tilvalgsmodul)',
        description: 'Kvass henter automatisk tilvalgskontrakt tilknyttet enhet'
      },
      {
        label: 'Dokumentasjon / FDV tilknyttet tilvalg (forutsetter aktivering av tilvalgsmodul)',
        description: 'Autogenerering av dokumentasjon / FDV tilknyttet tilvalg'
      },
      {
        label: 'Deling av kontrakter tilknyttet enheten (forutsetter aktivering av digitale salgsprosesser)',
        description: 'Kvass henter automatisk kjøpsbekreftelse og kjøpekontrakt tilknyttet enhet'
      },
      {
        label: 'Reklamasjonshåndtering (forutsetter aktivering av reklamasjonsmodul)',
        description: 'Kjøper kan registrere reklamasjoner via Min Side'
      },
    ],
  },
]

const Base = {
  value: 'base',
  label: 'Grunnpakke',
  description:
    'Presenter og selg eiendomsprosjekter på en helt ny måte. Du oppretter raskt og enkelt selv boliger og prosjekter med ønskede moduler.',
  icon: './logo.svg',
  iconType: 'image',
  features: [
    {
      label: 'Hjelp til å komme i gang',
      description: 'Som ny kunde får du hjelp inntil 3t med å komme i gang',
    },
    { label: 'Kundeoppfølgingssystem', description: 'Enkel håndtering av interessenter' },
    {
      label: 'Prosjekt- /Boligarkiv',
      description: 'Ha full oversikt over dataene på sluttførte boliger og prosjekter',
    },
    { label: 'Dokumentarkiv', description: 'Ha tilgang på alle kontrakter i arkivet' },
    {
      label: 'Hosting og backup',
      description: 'Vi tar oss av hosting og kjører daglige backups av dataene dine',
    },
    {
      label: 'Personlig dashbord',
      description: 'Ha oversikt over oppgaver, frister og hendelser knyttet til dine prosjekter',
    },
    { label: 'Produktkatalog', description: 'Opprett og vedlikehold dine produkter' },
    { label: 'Boligtyper', description: 'Opprett og vedlikehold dine boligtyper' },
    { label: 'Brukeradministrering', description: 'Legg enkelt til brukere og tildel roller / tilganger' },
    {
      label: 'Kursplattform',
      description: 'Du får tilgang til en rekke hjelpeverktøy som video, manualer og interaktive guider',
    },
    { label: 'Enkel opprettelse av prosjekter/boliger', description: 'Sett opp nye prosjekter/boliger på få minutter' },
  ],
}

const Modules = [
  {
    value: 'project',
    label: 'Prosjekt',
    summaryLabel: 'Prosjekt - Grunnpakke',
    description: '',
    icon: ['fad', 'drafting-compass'],
    availableAddons: Addons.map(a => a.value),
    defaultAddons: [],
    features: [
      { label: 'Dashbord', description: 'Ha oversikt over all aktivitet på prosjektet' },
      { label: 'Kundeoppfølgingssystem', description: 'Enkel håndtering av interessenter' },
      { label: 'Oppgavestyring', description: 'Opprett oppgaver knyttet til prosjekter' },
      { label: 'Dokumentarkiv', description: 'Ha tilgang på alle kontrakter i arkivet' },
      {
        label: 'Hosting og backup',
        description: 'Vi tar oss av hosting og kjører daglige backups av dataene dine',
      },
      { label: 'Min Side', description: 'Gi dine kunder tilgang til Min Side via egen login' },
    ],
  },
  {
    value: 'residential',
    label: 'Enkelt bolig',
    icon: ['fad', 'home'],
    availableAddons: ['purchase', 'shop-sale', 'presentation'],
    defaultAddons: [],
    features: [
      { label: 'Dashbord', description: 'Ha oversikt over all aktivitet' },
      { label: 'Kundeoppfølgingssystem', description: 'Enkel håndtering av interessenter' },
      { label: 'Oppgavestyring', description: 'Opprett oppgaver knyttet til boligen' },
      { label: 'Dokumentarkiv', description: 'Ha tilgang på alle kontrakter i arkivet' },
      {
        label: 'Hosting og backup',
        description: 'Vi tar oss av hosting og kjører daglige backups av dataene dine',
      },
      { label: 'Min Side', description: 'Gi dine kunder tilgang til Min Side via egen login' },
    ],
  },
]

export { Addons, Modules, Base }
export default {
  Addons,
  Modules,
  Base,
}
