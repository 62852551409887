<template>
  <button
    class="pricing-calculator__view-toggle"
    :title="$route.query.format == 'pdf' ? 'Kalkulatorvisning' : 'Tabellvisning'"
    @click="toggleTableView"
  >
    {{ $route.query.format == 'pdf' ? 'Kalkulatorvisning' : 'Tabellvisning' }}
    <FontAwesomeIcon :icon="['far', this.$route.query.format === 'pdf' ? 'calculator' : 'table']" />
  </button>
</template>

<script>
export default {
  methods: {
    async toggleTableView() {
      const query = Object.assign({}, this.$route.query)
      query.format = query.format === 'pdf' ? null : 'pdf'
      await this.$router.push({ query })
    },
  },
}
</script>

<style lang="scss">
.pricing-calculator__view-toggle {
  border: 1px solid $border-color;
  padding: 0.25rem 0.5rem;
  border-radius: $border-radius;
  font-size: 0.8em;

  display: flex;
  align-items: center;

  &:hover {
    border-color: darken($border-color, 10%);
  }

  svg {
    margin-left: 0.5rem;
  }
}

@media print {
  .pricing-calculator__view-toggle {
    display: none;
  }
}
</style>