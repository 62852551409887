<template>
  <Loader :value="promise" id="kvass-pricing">
    <component :is="component" :value="data" :vendorId="vendor" />
    <div class="kvass-pricing__kpi">
      Vi justerer våre priser årlig den 31.12. i henhold til KPI - Sist justering fant sted {{ kpi }}.
    </div>
  </Loader>
</template>

<script>
import Calculator from '@/components/Calculator'
import PDF from '@/components/Pdf'
import { LoaderComponent as Loader } from 'vue-elder-loader'

export default {
  name: 'kvass-pricing',
  data() {
    return {
      data: null,
      promise: null,
    }
  },
  watch: {
    vendor: {
      handler: 'fetch',
      immediate: true,
    },
  },
  computed: {
    component() {
      if (this.$route.query.format === 'pdf') return PDF
      return Calculator
    },
    kpi() {
      return `31.12.${new Date().getFullYear() - 1}`
    },
    vendor() {
      return this.$route.query.vendor || this.$root.$el.getAttribute('data-vendor') || 'kvass'
    },
  },
  methods: {
    fetch() {
      this.promise = fetch(`${process.env.VUE_APP_MANAGER_ENDPOINT}/api/pricing/${this.vendor}`)
        .then(res => res.json())
        .then(res => (this.data = res))
    },
    setFavicon() {
      const el = document.querySelector('head > link[rel="icon"]')
      if (this.vendor) {
        el.setAttribute(
          'href',
          `${process.env.VUE_APP_MANAGER_ENDPOINT}/api/vendor/${this.vendor.toLowerCase()}/favicon`,
        )
      }
    },
  },
  created() {
    this.fetch()
    this.setFavicon()
  },
  components: {
    Loader,
  },
}
</script>

<style lang="scss">
.kvass-pricing {
  &__kpi {
    color: white;
    text-align: center;
    margin-top: 1rem;
  }
}
</style>
