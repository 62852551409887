<template>
  <div class="kvass-pricing-addon" :class="{ 'kvass-pricing-addon--active': active }" v-on="$listeners">
    <FontAwesomeIcon class="kvass-pricing-addon__icon" v-if="itemContent.icon" :icon="itemContent.icon" size="lg" />
    <div class="kvass-pricing-addon__content">
      <div class="kvass-pricing-addon__header">
        <div class="kvass-pricing-addon__label">{{ itemContent.label }}</div>
        <div class="kvass-pricing-addon__cost">
          <div v-if="recurring !== undefined" class="kvass-pricing-addon__cost-recurring">
            {{ recurring | Currency }} / mnd.
          </div>
          <div v-if="onetime !== undefined" class="kvass-pricing-addon__cost-onetime">
            {{ onetime | Currency }}
          </div>
        </div>
      </div>
      <div class="kvass-pricing-addon__description" v-html="description"></div>
      <FeatureTrigger :value="itemContent" />
      <Tag v-if="itemContent.tag">{{ itemContent.tag }}</Tag>
    </div>
  </div>
</template>

<script>
import { ValueComp } from '@/utils'
import { Currency } from '@/filters'
import { ChoiceComponent as Choice } from 'vue-elder-choice'
import FeatureTrigger from '@/components/FeatureTrigger'
import Tag from '@/components/Tag'

export default {
  props: {
    value: Object,
    active: Boolean,
    include: Array,
    content: Array,
  },
  computed: {
    description() {
      if (typeof this.itemContent.description === 'function')
        return this.itemContent.description(this.$parent.$parent.$parent.data, this.$route.query.vendor)
      return this.itemContent.description
    },
    recurring() {
      const isRecurring = x => x.type === 'monthly'
      const monthly = this.value.price.filter(isRecurring)
      if (!monthly.length) return

      let sum = 0

      monthly.forEach(price => (sum += price.value))
      this.include.forEach(item => {
        item.price.filter(isRecurring).forEach(price => (sum += price.value))
      })

      return sum
    },
    onetime() {
      const isFixed = x => x.type === 'fixed'
      const fixed = this.value.price.filter(isFixed)
      if (!fixed.length) return

      let sum = 0

      fixed.forEach(price => (sum += price.value))
      this.include.forEach(item => {
        item.price.filter(isFixed).forEach(price => (sum += price.value))
      })

      return sum
    },
    itemContent() {
      return this.content.find(addon => addon.value === this.value.id)
    },
  },
  filters: {
    Currency,
  },
  components: {
    Choice,
    FeatureTrigger,
    Tag,
  },
}
</script>

<style lang="scss">
.kvass-pricing-addon {
  $space: 1.25rem;

  display: flex;
  align-items: center;

  width: 100%;

  border: 1px solid $border-color;
  border-radius: $border-radius;
  padding: $space $space $space $space;

  position: relative;

  text-align: left;

  cursor: pointer;

  &--active {
    color: var(--primary);
    background-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 0.1);
    border-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 0.2);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    margin-bottom: 5px;
  }

  &__cost {
    font-size: 0.7em;
    display: flex;
    white-space: nowrap;

    & > * + * {
      margin-left: 10px;

      &:before {
        content: ' + ';
      }
    }
  }

  &__content {
    flex-grow: 1;
    margin-right: $space/2;
    align-self: flex-start;
  }

  &__label {
    font-weight: bold;
  }

  &__description {
    font-size: 0.8em;
    opacity: 0.5;
  }

  &__icon {
    margin-right: $space;
    width: 40px;
  }
}
</style>
