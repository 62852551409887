function Limit(val, limit = 30) {
  if (val.length < limit) return val
  return `${val.substr(0, limit)}...`
}

function Currency(val, locale = 'nb', currency = 'NOK', decimals = 0) {
  if ([null, undefined].includes(val)) return
  return val.toLocaleString(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: decimals,
  })
}

export { Currency, Limit }
