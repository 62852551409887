import Template from '../base'
import { Currency } from '@/filters'

const Addons = [
  {
    value: 'presentation',
    cost: {
      project: { onetime: 50000, recurring: 0 },
      residential: { onetime: 0, recurring: 150 },
    },
  },
  {
    value: 'bid',
    cost: {
      project: { onetime: 0, recurring: 1200 },
    },
  },
  {
    value: 'purchase',
    cost: {
      project: { onetime: 0, recurring: 900 },
      residential: { onetime: 250, recurring: 0 },
    },
  },
  {
    value: 'shop-presentation',
    cost: {
      project: { onetime: 0, recurring: 1650 },
    },
  },
  {
    value: 'shop-sale',
    cost: {
      project: { onetime: 0, recurring: 2100 },
      residential: { onetime: 650, recurring: 0 },
    },
  },
]

const Base = {
  value: 'base',
  label: 'Grunnpakke',
  description:
    '<b><i class="far fa-long-arrow-right" style="margin-right: .25rem"></i> Registrer deg før 31.12.21 og slipp etableringskostnaden.</b> <br>Presenter og selg eiendomsprosjekter på en helt ny måte. Du oppretter raskt og enkelt selv boliger og prosjekter med ønskede moduler.',
  cost: {
    onetime: 9900,
    recurring: 800,
  },
}

const Modules = [
  {
    value: 'project',
    cost: {
      onetime: 0,
      recurring: 500,
    },
  },
  {
    value: 'residential',
    cost: {
      onetime: 0,
      recurring: 100,
    },
  },
]

const Discounts = [
  {
    value: 400,
    type: 'fixed',
    label: 'Prosjekt 1-9 - Bud',
    usableWith: ['bid'],
    priceTypes: ['recurring'],
    criteria: data => {
      return data.units < 10 && data.module === 'project'
    },
  },
  {
    value: 250,
    type: 'fixed',
    label: 'Prosjekt 1-9 - Kjøpekontrakt',
    usableWith: ['purchase'],
    priceTypes: ['recurring'],
    criteria: data => {
      return data.units < 10 && data.module === 'project'
    },
  },
  {
    value: 650,
    type: 'fixed',
    label: 'Prosjekt 1-9 - Tilvalg',
    usableWith: ['shop-presentation'],
    priceTypes: ['recurring'],
    criteria: data => {
      return data.units < 10 && data.module === 'project'
    },
  },
  {
    value: 700,
    type: 'fixed',
    label: 'Prosjekt 1-9 - Tilvalg',
    usableWith: ['shop-sale'],
    priceTypes: ['recurring'],
    criteria: data => {
      return data.units < 10 && data.module === 'project'
    },
  },
  {
    value: 40000,
    type: 'fixed',
    label: 'Prosjekt 2-9',
    usableWith: ['presentation'],
    priceTypes: ['onetime'],
    criteria: data => {
      return data.units <= 9 && data.units >= 2 && data.module === 'project'
    },
  },
  {
    value: 25000,
    type: 'fixed',
    label: 'Prosjekt 10-39',
    usableWith: ['presentation'],
    priceTypes: ['onetime'],
    criteria: data => {
      return data.units >= 10 && data.units <= 39 && data.module === 'project'
    },
  },
]

const UnitSteps = [
  { value: 2, label: '2 - 9' },
  { value: 20, label: '10 - 39' },
  { value: 40, label: '40+' },
]

export default {
  Name: 'Boligporten',
  Theme: undefined,
  Description:
    '<b>Innkjøpspriser</b> for Mestergruppen AS.<br><br>Priser er justert i forhold til antall kunder som er i gang - velg trinn nedenfor:',
  LogoBackground: '#f5f5fb',
  Logo: 'https://mestergruppen.no/css/images/MGLogo.svg',
  Addons,
  Disclaimer: 'Produkter faktureres pr. kvartal og alle priser er ekskl. mva.',
  SubscriptionInfo: `<b>Avtalens varighet</b><div>Du velger selv hvilke produkter du ønsker å aktivere på hvert prosjekt – så snart du markerer prosjektet som fullført vil produktene bli deaktivert.
  Alle produkter har 6 mnd. bindingstid. Etter 6 mnd. gjelder 1 mnd. oppsigelse.</div>`,
  Modules,
  Discounts,
  Base,
  UnitSteps,
  Discounts,
  CompareWith: 'default',
  RelatedVendors: [
    // { value: 'mestergruppen', label: 'Utsalgspriser (Forslag)' },
    { value: 'mestergruppen-innkjop-trinn-1', label: '1-25 kunder (ca. 20%)' },
    { value: 'mestergruppen-innkjop-trinn-2', label: '26-99 kunder (ca. 30%)' },
    { value: 'mestergruppen-innkjop-trinn-3', label: '100+ kunder (ca. 35%)' },
  ],
}
