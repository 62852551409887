<template>
  <div class="kvass-pricing-tag">
    <slot />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.kvass-pricing-tag {
  font-size: 0.7em;
  padding: 2px 5px;
  background-color: var(--primary);
  color: white;
  border-radius: 0 0 0 $border-radius;
  position: absolute;
  top: 0;
  right: 0;
}
</style>
