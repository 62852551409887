// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".pdf{border-radius:3px;background-color:#fff;border-collapse:collapse;width:100%;max-width:1000px;border:2px solid rgba(0,0,0,.03);margin:0 auto}@media print{.pdf{font-size:.8em}}.pdf h1{margin:0;font-size:1.5em}.pdf th{background-color:rgba(0,0,0,.025)}.pdf th:first-child{text-align:left}.pdf td,.pdf th{border:1px solid #eaeaea;padding:1rem}.pdf td:nth-child(n+2){text-align:right}.pdf td:first-child{background-color:rgba(0,0,0,.025)}.pdf td:empty{opacity:.7;font-size:.7em;text-align:center;background-color:rgba(0,0,0,.05)}.pdf td:empty:before{content:\"Ikke tilgjengelig\"}.pdf div{white-space:nowrap}.pdf div:nth-child(n+2):before{content:\"+ \"}.pdf .establishment,.pdf .fixed,.pdf .monthly{display:inline;font-size:.7em;opacity:.7}.pdf__units{background-color:rgba(0,0,0,.03)}.pdf__header{display:flex;justify-content:space-between;align-items:center}.pdf img{max-height:25px}.pdf .addon{display:flex;align-items:center}.pdf .addon:before{content:\"└\";margin-right:1rem;opacity:.2}.pdf .addon__description{display:block;font-size:.7em;opacity:.7;margin-top:2px}", ""]);
// Exports
exports.locals = {
	"primary": "#1d56d8",
	"secondary": "#4274e6",
	"warning": "#e9ab05",
	"success": "#0abb59",
	"error": "#e7201b",
	"light-grey": "#f7f7f7",
	"dark": "#272727",
	"grey": "#e3e3e3"
};
module.exports = exports;
