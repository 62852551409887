<template>
  <Card class="pricing-calculator" theme="flat" :style="theme">
    <template #header>
      <ViewToggle />
      <img v-if="logo" :src="logo" width="250px" :alt="vendor.Name" />
      <h1 v-else class="pricing-calculator__title">{{ vendor.Name }}</h1>
      <div v-if="vendor.Description" class="pricing-calculator__description" v-html="vendor.Description"></div>
      <div v-if="vendor.RelatedVendors && vendor.RelatedVendors.length" class="pricing-calculator__related-vendors">
        <div class="pricing-calculator__related-vendors-items">
          <ButtonComponent
            v-for="item in vendor.RelatedVendors"
            tag="router-link"
            :theme="item.value === vendor.Value ? 'primary' : 'default'"
            :key="item.value"
            :label="item.label"
            :to="{ query: { vendor: item.value } }"
          />
        </div>
      </div>
    </template>
    <template #default>
      <Units v-model="data.units" :values="value.steps" :item="item" :content="vendor.Modules" />
      <Addons v-if="data.units" v-model="data.addons" :units="data.units" :addons="addons" :content="vendor.Addons" />
      <Base v-model="data.base" :pricing="value" :content="vendor.Base" :step="step" />
      <Features :data="vendor" />
    </template>
    <template #footer>
      <div class="pricing-calculator-summary">
        <div class="pricing-calculator-summary__recurring">
          <span>
            Pris
            <button class="pricing-calculator-summary__details" @click="showDetails = !showDetails">
              <template v-if="showDetails">Skjul detaljer</template>
              <template v-else>Vis detaljer</template>
              <FontAwesomeIcon :icon="['fad', showDetails ? 'angle-up' : 'angle-down']" />
            </button>
          </span>
          <span>{{ sum.recurring | Currency }} / mnd.</span>
        </div>
        <div class="pricing-calculator-summary__onetime">
          <span>Etablering</span>
          <span>{{ sum.fixed | Currency }}</span>
        </div>

        <div v-if="showDetails" class="pricing-calculator-summary__lines">
          <table class="pricing-calculator-summary__line" v-for="line in summaryDetails" :key="line.label">
            <tr>
              <td>{{ line.label }}</td>
              <td v-if="line.price.recurring">{{ line.price.recurring | Currency }} / mnd.</td>
            </tr>
            <tr v-if="line.price.fixed">
              <td>Etablering</td>
              <td>{{ line.price.fixed | Currency }}</td>
            </tr>
          </table>
        </div>

        <Alert class="pricing-calculator__additional-info">
          <div v-if="subscriptionInfo" v-html="subscriptionInfo"></div>

          <div style="margin-top: 1rem">
            <b>Ønsker du å teste løsningen før du kommer i gang?</b>
            <div style="margin-top: 0.5rem">Få tilgang på en demo konto der du kan teste fritt i 14 dager.</div>
          </div>
        </Alert>

        <div class="pricing-calculator-summary__disclaimer" v-html="vendor.Disclaimer"></div>
      </div>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/Card'
import Fields from './Fields'
import { Calculate, getVendor, isInRange } from '@/pricing'
import { Currency } from '@/filters'
import { AlertComponent as Alert } from 'vue-elder-alert'
import { ButtonComponent } from 'vue-elder-button'
import Features from '@/components/Features'
import { HexToHSL, Clone, ParseMonths } from '@/utils'
import FeatureTrigger from '@/components/FeatureTrigger'
import ViewToggle from '@/components/ViewToggle'

export default {
  data() {
    return {
      data: {
        base: false,
        module: 'project',
        units: 10,
        addons: [],
      },
      showDetails: false,
    }
  },
  props: {
    value: Object,
    vendorId: String,
  },
  computed: {
    vendor() {
      return getVendor(this.vendorId)
    },
    sum() {
      return Calculate(this.data, this.step.items, this.vendor)
    },
    step() {
      return this.value.steps.find(step => isInRange(this.data.units, step.condition))
    },
    item() {
      return this.step.items.find(item => item.id === this.data.module)
    },
    addons() {
      return this.item?.addons
    },
    theme() {
      const themeColor = this.value.settings.theme?.primary
      let theme = {
        '--pricing-header': themeColor ?? '#f5f5fb',
      }

      if (themeColor) {
        let hsl = HexToHSL(themeColor)
        theme['--primary'] =
          theme['--fa-primary-color'] =
          theme['--fa-secondary-color'] =
          theme['--vue-elder-primary'] =
            themeColor
        theme['--primary-s'] = hsl.s + '%'
        theme['--primary-h'] = hsl.h
        theme['--primary-l'] = hsl.l + '%'
      }

      return theme
    },
    logo() {
      return `${process.env.VUE_APP_MANAGER_ENDPOINT}/api/vendor/${this.vendorId}/logo${
        this.value.settings.theme?.primary ? '-light' : ''
      }`
    },
    text() {
      const base = this.vendor.Base
      const addons = this.vendor.Addons
      const modules = this.vendor.Modules

      let all = [base, ...modules, ...addons]

      return all
    },
    summaryDetails() {
      let all = [this.item, ...this.addons.filter(addon => this.data.addons.includes(addon.id))]

      if (this.data.base) {
        all.push(this.step.items.find(item => item.type === 'app'))
      }

      const prices = all.reduce((res, item) => {
        const fixed = item.price.find(p => p.type === 'fixed' || p.type === 'establishment')?.value
        const recurring = item.price.find(p => p.type === 'monthly')?.value

        const include = this.text.find(x => x.value === item.id)?.include
        const includedItem = this.addons.find(x => include?.includes(x.id))
        const includedPrice = includedItem?.price.reduce(
          (res, price) => {
            res[price.type === 'monthly' ? 'recurring' : 'fixed'] += price.value
            return res
          },
          { fixed: 0, recurring: 0 },
        )

        res[item.id] = {
          fixed,
          recurring,
        }

        if (includedPrice) {
          let arr = ['recurring', 'fixed'].forEach(type => (res[item.id][type] += includedPrice[type]))
        }

        return res
      }, {})

      const lines = Clone(this.text)
        .map(line => {
          let price = prices[line.value === 'base' ? 'app' : line.value]

          return {
            label: line.summaryLabel || line.label,
            price,
          }
        })
        .filter(line => line.price)

      return lines
    },
    subscriptionInfo() {
      return this.vendor.SubscriptionInfo?.replace(
        '$lockInPeriod',
        ParseMonths(this.value.settings.subscription.lockInPeriod),
      ).replace('$noticePeriod', ParseMonths(this.value.settings.subscription.noticePeriod))
    },
  },
  methods: {
    ParseMonths,
  },
  filters: {
    Currency,
    Percent(value) {
      return Math.round(value * 100) + '%'
    },
  },
  components: {
    Card,
    Features,
    Alert,
    ButtonComponent,
    FeatureTrigger,
    ...Fields,
    ViewToggle,
  },
}
</script>

<style lang="scss">
.pricing-calculator {
  position: relative;
  max-width: 900px;
  margin-inline: auto;

  &__title {
    margin: 0;
    font-size: 1.5em;
    color: var(--primary);
  }

  &__description {
    margin-top: 1rem;
    text-align: center;
    font-size: 0.8em;
  }

  &__additional-info {
    margin-top: 2rem;

    .kvass-pricing-feature-trigger {
      font-size: 1em;
    }

    &-campaign {
      display: inline-block;
      background-color: rgba(black, 0.05);
      margin-top: 0.25rem;
      border-radius: $border-radius;
      padding: 3px 8px;
    }
  }

  &__related-vendors {
    margin-top: 0.5rem;
    width: 100%;

    &-items {
      width: inherit;

      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      & > * {
        margin-left: 0.5rem;
        margin-top: 0.5rem;
      }
    }
  }

  .pricing-calculator__view-toggle {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  & > .card__header {
    background-color: var(--pricing-header);
  }

  & > .card__content {
    & > * + *:not(.elder-modal) {
      margin-top: 2rem;
    }
  }
}

.pricing-calculator-summary {
  flex-grow: 1;

  &__disclaimer {
    font-size: 0.8em;
    opacity: 0.7;
    margin-top: 1rem;
    text-align: center;
    font-style: italic;
  }

  &__discount {
    opacity: 0.5;
    text-decoration: line-through;
    margin-right: 0.5em;
    font-weight: normal;

    &-value {
      background-color: $border-color;
      padding: 2px 3px;

      border-radius: 15px;
      margin-right: 0.5em;
    }
  }

  &__details {
    font-size: 0.8em;
    padding: 3px 7px;
    border-radius: 15px;
    background-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 0.1);
    margin-left: 0.5rem;

    svg {
      margin-left: 0.5em;
    }
  }

  &__onetime,
  &__recurring {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    align-items: flex-end;

    & > span:nth-child(2) {
      font-size: 1.7em;
      color: var(--primary);
    }
  }

  &__lines {
    border-top: 1px solid $border-color;
    margin-top: 1rem;
    padding-top: 1rem;
  }

  &__line {
    width: 100%;
    font-size: 0.8em;

    & + & {
      margin-top: 0.5rem;
    }

    tr:first-child {
      font-weight: bold;
    }

    td:nth-child(2) {
      text-align: right;
    }
  }
}
</style>
