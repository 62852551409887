// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".kvass-pricing-features{max-height:85vh;width:100%;max-width:500px}.kvass-pricing-features .card__header{min-height:0;background-color:hsla(var(--primary-h),var(--primary-s),var(--primary-l),.05)}.kvass-pricing-features .card__content{overflow-y:auto}.kvass-pricing-features__title{margin:0 0 .5rem}.kvass-pricing-features__feature-description{font-size:.8em;font-weight:400;opacity:.7}.kvass-pricing-features table{margin-top:2rem;width:100%;border-collapse:collapse}.kvass-pricing-features table td{padding:10px 0;border-top:1px solid #e3e3e3;border-bottom:1px solid #e3e3e3;font-weight:700}.kvass-pricing-features table td:nth-child(2){text-align:center;padding:0 1em 0 1.5em}", ""]);
// Exports
exports.locals = {
	"primary": "#1d56d8",
	"secondary": "#4274e6",
	"warning": "#e9ab05",
	"success": "#0abb59",
	"error": "#e7201b",
	"light-grey": "#f7f7f7",
	"dark": "#272727",
	"grey": "#e3e3e3"
};
module.exports = exports;
