// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".kvass-pricing-feature-trigger{color:var(--primary);font-weight:700;font-size:.8em;cursor:pointer;margin-top:10px;border:1px solid rgba(0,0,0,.075);border-radius:3px;padding:2px 8px;transition:background-color .15s ease-out}.kvass-pricing-feature-trigger:hover{background-color:hsla(var(--primary-h),var(--primary-s),var(--primary-l),.1);border-color:hsla(var(--primary-h),var(--primary-s),var(--primary-l),.3)}.kvass-pricing-feature-trigger svg{margin-left:5px}", ""]);
// Exports
exports.locals = {
	"primary": "#1d56d8",
	"secondary": "#4274e6",
	"warning": "#e9ab05",
	"success": "#0abb59",
	"error": "#e7201b",
	"light-grey": "#f7f7f7",
	"dark": "#272727",
	"grey": "#e3e3e3"
};
module.exports = exports;
